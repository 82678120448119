import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {injectIntl} from 'gatsby-plugin-intl'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'
import {Page} from '../styles/page'
import {options} from '../utils/pages'

const Image = styled.img`
  margin: 1.5rem 0;
`
const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Info = styled.article`
  h3,
  p {
    text-align: center !important;
  }

  p {
    background: ${(props) => props.theme.colors.cream};
    max-width: 50%;
    margin: 0 auto;
    @media all and (max-width: 624px) {
      max-width: 85%;
    }
    padding: 0.5rem;
  }
`

const Payment = ({
                     location,
                     intl
                 }) => {
    const [pagedata, setPageData] = useState()
    const [imagesrc, setImageSrc] = useState()

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(
                `https://cdn.contentful.com/spaces/${process.env.CONTENTFUL_SPACE_ID}/entries?access_token=${process.env.CONTENTFUL_ACCESS_TOKEN}&content_type=page&fields.name=payment`,
            )
            const {items} = await response.json()
            if (items && items.length) setPageData(items[0])

            try {
                const res = await fetch(
                    `https://cdn.contentful.com/spaces/${process.env.CONTENTFUL_SPACE_ID}/assets/${items[0].fields.image.sys.id}?access_token=${process.env.CONTENTFUL_ACCESS_TOKEN}`,
                )
                const img = await res.json()
                if (img) setImageSrc(img.fields.file.url)
            } catch (error) {
                console.error(error)
            }
        }

        fetchData().then(r => r)
    }, [])

    const text = pagedata && pagedata['fields'][intl.locale]


    return (
        <Layout location={location.pathname}>
            <Seo title={intl.formatMessage({id: 'payment'})}/>
            <Section>{imagesrc && <Image src={imagesrc} alt='QR code'/>}</Section>
            <Page>
                <Info>{text && documentToReactComponents(text, options)}</Info>
            </Page>
        </Layout>
    )
}

export default injectIntl(Payment)
